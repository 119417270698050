import React from "react";
import { navigate, Link } from "gatsby";
import { GQLComponent } from "../../../types";
import { HomePage as Constants } from "../../../helpers/constants";
import {
  GetDefaultImageUrl,
  GetSingleLineContent,
} from "../../../helpers/query-data";
import {
  Card,
  CardMedia,
  Typography,
  Button,
  makeStyles,
  useMediaQuery,
  Theme,
} from "@material-ui/core";

const UseStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
  },

  cardMedia: {
    paddingTop: "30.3125%",
  },

  cardContent: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "10%",
    background: "rgba(5, 5, 5, 0.5)",
    width: "30%",
    padding: ".5rem",
    margin: "auto",
    height: "fit-content",
    display: "none",

    "& p": {
      color: "white",
    },

    "& Button": {
      background: "#AFBD21",
      position: "absolute",
      top: "115%",
      left: "0",
      display: "none",

      "&:hover": {
        background: "#AFBD21",
      },

      "& a": {
        color: "black",

        "&:hover": {
          textDecoration: "none",
        },
      },

      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },

    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

/* Allows navigation by clicking the slide picture on smaller displays
 * - isScreenLarge -> True if using a tablet or desktop display
 * - path -> The route to navigate to when clicked
 */
const OnClick = (isScreenLarge: boolean, path: string): void => {
  if (!isScreenLarge) {
    navigate(path);
  }
};

type SlideProps = {
  path: GQLComponent;
  image: GQLComponent;
  text: GQLComponent;
  slideRef: React.RefObject<unknown>;
};

/*
 * path -> URL to go to when "More Info" is clicked
 * image -> Image to display on the slide
 * text -> The content component the slide article
 * slideRef -> React ref to this slide
 */
const Slide = (props: SlideProps): JSX.Element => {
  const Classes = UseStyles();
  const Image = GetDefaultImageUrl(props.image);
  const SlideText = GetSingleLineContent(props.text);
  const ProductsPath = props.path ? GetSingleLineContent(props.path) : null;
  const IsScreenLarge = useMediaQuery((theme) => {
    const MyTheme = theme as Theme;
    return MyTheme.breakpoints.up("md");
  });

  return (
    <Card
      onClick={() => OnClick(IsScreenLarge, ProductsPath ?? "")}
      className={Classes.card}
      ref={props.slideRef}
    >
      <CardMedia image={Image} className={Classes.cardMedia} />
      <div className={Classes.cardContent}>
        <Typography variant="body1">{SlideText}</Typography>
        <Button variant="contained">
          <Link to={ProductsPath ?? ""}>{Constants.LearnMoreText}</Link>
        </Button>
      </div>
    </Card>
  );
};

export default Slide;
