import Slide from "./slideshow/slide";
import React, { useEffect, useRef } from "react";
import { GetComponentByName } from "../../helpers/query-data";
import { GQLItem } from "../../types";
import { HomePage as Constants } from "../../helpers/constants";
import {
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  useMediaQuery,
  Theme,
} from "@material-ui/core";

const UseStyles = makeStyles((theme) => ({
  slideshow: {
    width: "100%",
    marginTop: "1rem",
  },

  radioWrapper: {
    textAlign: "center",
  },

  radioGroup: {
    marginTop: ".25rem",
    display: "inline-block",
  },

  radioButton: {
    padding: ".15rem",

    "&:hover": {
      color: "#AFBD21",
    },

    [theme.breakpoints.up("sm")]: {
      padding: ".25rem",
    },
  },
}));

/* Creates React references for each slide
 * - slides -> List of Crystallize items
 */
const CreateSlideRefs = (slides: GQLItem[]): React.RefObject<GQLItem>[] => {
  const SlideRefs = [];
  slides.forEach(function () {
    SlideRefs.push(React.createRef<GQLItem>());
  });
  return SlideRefs;
};

/* Advances the slideshow to the next slide
 * - slideRefs -> List of React references for each slide
 * - value -> A number string index of which slide to show next
 * - setValue -> A function used to update the React state
 */
const ShowNextSlide = (
  slideRefs: React.RefObject<GQLItem>[],
  value: string,
  setValue: (newValue: string) => void
): void => {
  if (Number.parseInt(value) + 1 >= slideRefs.length) {
    setValue("0");
  } else {
    const newValue = (Number.parseInt(value) + 1).toString();
    setValue(newValue);
  }
};

/* Shows the current slide and hides all other slides
 * - slideRefs -> List of React references for each slide
 * - value -> A number string index of which slide is currently active
 */
const UpdateSlidesDisplay = (
  slideRefs: React.RefObject<GQLItem>[],
  value: string
): void => {
  slideRefs.forEach(function (slide) {
    const SlideAsHtml = (slide.current as unknown) as HTMLElement;

    if (slideRefs.indexOf(slide) === Number.parseInt(value)) {
      SlideAsHtml.style.display = "block";
    } else {
      SlideAsHtml.style.display = "none";
    }
  });
};

type SlideshowProps = { slides: GQLItem[] };

/*
 * slides -> Crystallize articles array of slide data
 */
const Slideshow = (props: SlideshowProps): JSX.Element => {
  const Classes = UseStyles();
  const SlideRefs = CreateSlideRefs(props.slides);
  const [Value, SetValue] = React.useState("0");
  const Timeout = useRef(null);
  const IsScreenLarge = useMediaQuery((theme) => {
    const MyTheme = theme as Theme;
    return MyTheme.breakpoints.up("sm");
  });

  /* Function called whenever the slide changes */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Timeout.current) {
      clearTimeout(Timeout.current);
    }

    const NewValue = event.target.value;
    SetValue(NewValue.toString());
    UpdateSlidesDisplay(SlideRefs, NewValue);
  };

  /* Sets a timer to update the slide every 5 seconds */
  useEffect(() => {
    Timeout.current = setTimeout(
      () => ShowNextSlide(SlideRefs, Value, SetValue),
      5000
    );
    UpdateSlidesDisplay(SlideRefs, Value);
  }, [SlideRefs.length, Value]);

  return (
    <div className={Classes.slideshow}>
      {props.slides.map((node, index) => (
        <Grid item xs={12} key={index}>
          <Slide
            slideRef={SlideRefs[index]}
            path={GetComponentByName(node.components, Constants.SlideUrlText)}
            image={GetComponentByName(
              node.components,
              Constants.SlideBannerImageText
            )}
            text={GetComponentByName(node.components, Constants.SlideTextText)}
          />
        </Grid>
      ))}
      <Grid item xs={12} className={Classes.radioWrapper}>
        <RadioGroup
          row
          value={Value}
          onChange={handleChange}
          className={Classes.radioGroup}
        >
          {props.slides.map((_node, index) => (
            <Radio
              size={IsScreenLarge ? "medium" : "small"}
              key={index}
              color="primary"
              className={Classes.radioButton}
              value={index.toString()}
            />
          ))}
        </RadioGroup>
      </Grid>
    </div>
  );
};

export default Slideshow;
