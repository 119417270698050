import React from "react";
import Image from "../image";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { HomePage as Constants } from "../../helpers/constants";
import { navigate } from "gatsby-link";

const UseStyles = makeStyles({
  headerWrapper: {
    textAlign: "center",
    margin: "1rem 0 1rem 0",
  },

  brandWrapper: {
    boxShadow: "0.15rem -0.15rem 0.35rem #C9C9C9",
    textAlign: "center",
  },

  sampleProductImage: {
    borderLeft: "0.1rem solid #C9C9C9",
    borderRight: "0.1rem solid #C9C9C9",
    borderTop: "0.1rem solid #C9C9C9",
    height: "12.5rem",

    "& img": {
      maxHeight: "12rem",
      maxWidth: "100%",
      width: "auto",
    },
  },

  brandLogo: {
    backgroundColor: "#1B1B1B",
    padding: "0.5rem 1rem 0.5rem 1rem",
    borderLeft: "0.1rem solid #1B1B1B",
    borderRight: "0.1rem solid #1B1B1B",
    borderBottom: "0.1rem solid #1B1B1B",
    height: "5rem",

    "& img": {
      height: "100%",
      width: "auto",
    },
  },

  spacer: {
    height: "3rem",
  },
});

/* Adds/removes the shadow when a grid item is moused-over */
const OnBrandHover = (event: React.MouseEvent<HTMLDivElement>) => {
  event.type === "mouseenter" && event.currentTarget
    ? (event.currentTarget.style.boxShadow = "0.15rem -0.15rem 0.35rem #666666")
    : (event.currentTarget.style.boxShadow =
        "0.15rem -0.15rem 0.35rem #C9C9C9");
};

const BrandGrid = (): JSX.Element => {
  const Styles = UseStyles();

  return (
    <>
      <Grid item xs={12} className={Styles.headerWrapper}>
        <Typography variant="h4">{Constants.BrowseByBrandText}</Typography>
        <Typography variant="body1">
          {Constants.BrowseByBrandDetails}
        </Typography>
      </Grid>
      {Constants.BrandNames.map((brand, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
          <div
            className={Styles.brandWrapper}
            onMouseEnter={OnBrandHover}
            onMouseLeave={OnBrandHover}
            onClick={() => navigate(`products/${brand}`)}
          >
            <div className={Styles.sampleProductImage}>
              <Image
                url={`${Constants.ProductImagesDir}${brand}.png`}
                altText={brand}
              />
            </div>
            <div className={Styles.brandLogo}>
              <Image
                url={`${Constants.BrandLogosDir}${brand}.png`}
                altText={brand}
              />
            </div>
          </div>
        </Grid>
      ))}
      <Grid item xs={12} className={Styles.spacer} />
    </>
  );
};

export default BrandGrid;
