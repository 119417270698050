import React from "react";
import Layout from "../components/layout";
import Slideshow from "../components/home/slideshow";
import BrandGrid from "../components/home/brand-grid";
import axios from "axios";
import { GQLQuery } from "../types";
import { HomePage } from "../helpers/constants";
import { Grid, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const UseStyles = makeStyles({
  banner: {
    backgroundColor: "#AFBD21",
    color: "#1B1B1B",
    borderRadius: "0.25rem",
    textAlign: "center",
    margin: "1rem 0 -0.5rem 0",

    "& svg": {
      verticalAlign: "bottom",
      marginRight: "5px",
    },
  },

  text: {
    display: "inline-block",
    lineHeight: "24px",
    marginLeft: "39px",
    textIndent: "-39px",

    "& a": {
      color: "#1B1B1B",
    },
  },
});

export const NewQuery = `
  {
    catalogue {
      children {
        ...HP_Folder
      }
    }
  }

  fragment HP_Folder on Folder {
    name
    children {
      ...HP_Document
    }
  }

  fragment HP_Document on Document {
    name
    path
    components {
      ...HP_Component
    }
  }

  fragment HP_Component on Component {
    name
    content {
      ...HP_ImageContent
      ...HP_SingleLineContent
    }
  }

  fragment HP_SingleLineContent on SingleLineContent {
    text
  }

  fragment HP_ImageContent on ImageContent {
    firstImage {
      url
      altText
    }
  }
`;

const Index = (): JSX.Element => {
  const Styles = UseStyles();
  const [Slides, SetSlides] = React.useState([]);

  React.useEffect(() => {
    axios
      .post(HomePage.CrystallizeHomePageTenant, {
        query: NewQuery,
      })
      .then((response) => {
        const QueryResult = response.data.data as GQLQuery;
        const Folders = QueryResult.catalogue.children;
        const MatchingFolders = Folders.filter(
          (folder) => folder.name === HomePage.SlideshowText
        );

        if (MatchingFolders.length === 1) {
          SetSlides(MatchingFolders[0].children);
        }
      });
  }, [Slides.length]);

  return (
    <Layout currentPage="/">
      <Slideshow slides={Slides} />
      <Grid item xs={12} className={Styles.banner} />
      <BrandGrid />
    </Layout>
  );
};

export default Index;
